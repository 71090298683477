
import React from "react"
import styled from "styled-components"

import { respondTo } from "../_respondTo"


const PartWrapper = styled.div`
  padding: 5vh 0;


  ${respondTo.md`

  `}

  
`

function Part({ myClass, children }) {

    return (<PartWrapper className={myClass}>{children}</PartWrapper>)

}

export default Part
